<script setup>
import Header from "@/Components/Header.vue";
import Footer from "@/Components/Footer.vue";
import {Head, router} from '@inertiajs/vue3'
import ChatInterface from "@/Components/ChatInterface.vue";
import {ref} from "vue";

const isServer = typeof document === 'undefined';

if (!isServer) {
    router.on('start', (event) => {
        gtag('event', 'page_view', {
            'page_location': event.detail.visit.url
        });
    });
}

let delayChatInterfaceRenderBy = 5000;
let showChatInterface = ref(false);

setTimeout(() => {
    showChatInterface.value = true;
}, delayChatInterfaceRenderBy);

</script>
<template>
    <Head>
        <title>SAMOS parcel delivery and tracking</title>
        <meta
            content="Ship your online products your way, with SAMOS shipping. Track your order, get help with delivery and set up an integrated shopping platform."
            head-key="description"
            name="description"/>
        <meta content="website" head-key="og:type" property="og:type">
        <meta content="SAMOS E-Commerce" head-key="og:site_name" property="og:site_name">
        <meta content="https://samos-e.com" head-key="og:url" property="og:url">
        <meta content="SAMOS parcel delivery and tracking" head-key="og:title" property="og:title">
        <meta
            content="Ship your online products your way, with SAMOS shipping. Track your order, get help with delivery and set up an integrated shopping platform."
            head-key="og:description"
            property="og:description">
        <meta content="@samos_e" head-key="twitter:site" name="twitter:creator"/>
        <meta content="summary_large_image" head-key="twitter:card" name="twitter:card">
    </Head>
    <section>
        <Header/>
        <slot/>
        <ChatInterface v-if="showChatInterface"/>
        <Footer/>
    </section>
</template>
